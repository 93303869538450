import React from 'react';

import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';

import styles from './ImageListModule.css';
import { StrapiButton } from '../../../atoms/Buttons/StrapiButton/StrapiButton';
import { Button, Media, Image } from '../../../models/Strapi';
import { Analytics } from '../../../services/Analytics/Analytics';
import { UrlService } from '../../../services/UrlService';

type ImageList = {
  images: Image[];
  subtitle?: string;
};

type ImageListModuleProps = {
  background?: Media;
  backgroundFallback?: string;
  title: string;
  moduleScreenId?: string;
  component: [Button, ImageList, ImageList];
  backgroundGradient: { color1: string, color2: string }
};

type ImageBlockProps = {
  imageList: ImageList;
  backgroundGradient: boolean
};

const ImageBlockWithSubtitle = ({ imageList, backgroundGradient }: ImageBlockProps) => {
  return (
    <div>
      <div className={classNames(styles.subtitle, { [styles.whiteText]: !!backgroundGradient })}>
        {/*this needs to be transformed into dynamic if reutilised*/}
        <ReactMarkdown
          components={{
            strong: ({ children }) => <span
              className={classNames(styles.highlighted, { [styles.whiteText]: !!backgroundGradient })}>{children}</span>
          }}
        >
          {imageList.subtitle}
        </ReactMarkdown>
      </div>

      <div className={styles.imageList}>
        {imageList.images.map((image) => (
          <img
            key={image.title}
            src={UrlService.createStrapiMediaUrl(image.media?.url)}
            alt={image.alt}
            className={styles.image}
          />
        ))}
      </div>
    </div>
  );
};
const ImageListModule = React.memo((props: ImageListModuleProps) => {
  const {
    title,
    moduleScreenId,
    backgroundGradient,
    component: [button, imageList1, imageList2]
  } = props;
  const getBackground = ({ background, backgroundFallback, backgroundGradient }: ImageListModuleProps) => {
    const result = {} as React.CSSProperties;

    if (background) {
      result.backgroundImage = `url(${UrlService.createStrapiMediaUrl(background.url)})`;
    } else if (backgroundGradient) {
      result.backgroundImage = `linear-gradient(${backgroundGradient.color1}, ${backgroundGradient.color2})`;
    } else if (backgroundFallback) {
      result.backgroundColor = backgroundFallback;
    }

    return result;
  };
  const handleAnalytics = (ctaText: string, moduleScreenId: string) => {
    void Analytics.trackEvent(
      Analytics.pageBuilderAdvantageAnalytics.mainModuleClick({
        moduleScreenId,
        ctaText
      })
    );
  };
  const content = [imageList1];

  if (imageList2) {
    content.push(imageList2);
  }

  return (
    <div className={styles.content} style={getBackground(props)}>
      <section className={styles.container}>
        <h2 className={classNames(
          styles.mainTitle,
          { [styles.whiteText]: !!backgroundGradient }
        )}>
          {title}
        </h2>
        <div>
          {content.map((imageList) => (
            <ImageBlockWithSubtitle
              key={imageList.subtitle}
              imageList={imageList}
              backgroundGradient={!!backgroundGradient}
            />
          ))}
          <div className={styles.cta}>
            <StrapiButton
              label={button?.label}
              action={button?.action}
              url={button?.url}
              gradient={{
                color1: button?.gradientColor1, //'#C28C2B'
                color2: button?.gradientColor2, //'#E6C14E',
                color3: button?.gradientColor3, //'#C28C2B',
                textColor: button?.textColor //'rgba(35, 31, 32, 1)',
              }}
              analyticsCallback={() => handleAnalytics(button?.label, moduleScreenId)}
              className={styles.ctaButton}
            />
          </div>
        </div>
      </section>
    </div>
  );
});

export default ImageListModule;
